import React, { createContext, useEffect } from 'react';
import { useSetState, useInterval } from '@mantine/hooks';
import { v4 as uuidv4 } from 'uuid';

const DataContext = createContext();

export function ProvideData(v) {
  const auth = useProvideData(v.platformContext);
  return <DataContext.Provider value={auth}>{v.children}</DataContext.Provider>;
}

export default DataContext;

export function useProvideData(props) {
  const [dataState, setDataState] = useSetState({
    sessionId: null,
    appLaunchedAt: null,
    playerName: '',
    results: null,
    sessionCount: 0,
    sortColumn: 'score',
  });
  const resultsInterval = useInterval(() => getResults(), 5000);

  // handle initial load
  useEffect(async () => {
    const appLaunchedAt = new Date().getTime();
    localStorage.setItem('appLaunchedAt', appLaunchedAt.toString());
    setDataState({ appLaunchedAt });

    await refreshSessionId();
    getResults();
  }, []);

  useEffect(() => {
    resultsInterval.start();
    return resultsInterval.stop;
  }, [dataState?.sessionId, dataState?.sortColumn]);

  const refreshSessionId = () => {
    return new Promise((resolve) => {
      const sessionId = uuidv4();
      localStorage.setItem('sessionId', sessionId);
      setDataState({ sessionId }, () => resolve());
    });
  };

  const getResults = () => {
    return new Promise(async (resolve) => {
      const updateState = {};
      const playerName = localStorage.getItem('playerName') || '';
      const host = window.location.hostname.includes(
        'relibirds.newrelic-es.com',
      )
        ? 'api.relibirds.newrelic-es.com'
        : `${window.location.hostname}:3333`;

      const url = `${window.location.protocol}//${host}/leaderboard?id=${dataState.sessionId}&playerName=${playerName}&sortColumn=${dataState.sortColumn}`;
      const results = await fetch(url);
      const json = await results.json();

      if (json?.sessionCount) {
        updateState.sessionCount = json.sessionCount;
      }

      updateState.results = json.results;

      setDataState(updateState);
      resolve(json.results);
    });
  };

  return {
    ...dataState,
    setDataState,
    refreshSessionId,
  };
}
